button.ploot-menu-button{
  margin-left: 15px;
  margin-bottom: 10px;
  border: 0;
  padding: 0;
  background-color: red;
}

button.ploot-menu-button:hover{
    filter: invert(1);
}

div.ploot-main{
  width: 100vw;
  height: 100vh;
  background-color: red;
}

div.ploot-row{
   display: flex;
   align-items: center;
   margin: 5px 0 5px 0;
}

div.ploot-col{
  display: flex;
  flex-direction: column;
  align-items: center;
}

div.ploot-f-start{
  justify-content: flex-start;
}

div.ploot-f-end{
  justify-content: flex-end;
}